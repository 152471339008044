<script lang="ts" setup>
import { Delta, QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Article } from "types/Article";

const props = defineProps({
  article: {
    type: Object as PropType<Article>,
    required: true,
  },
  hideTitle: {
    type: Boolean,
    default: false,
  },
});

const isArticleMigrated = ref(false);

onMounted(() => {
  if (props.article.content) {
    try {
      quillContent.value = new Delta(
        props.article.content ? JSON.parse(props.article.content) : undefined
      );
    } catch (error) {
      isArticleMigrated.value = true;
    }
  }
});

const quillContent = ref();
</script>

<template>
  <div id="article-viewer" class="flex flex-col">
    <h2 v-if="hideTitle != true" class="text-h4 mb-4">
      {{ article.title }}
    </h2>
    <div
      v-if="isArticleMigrated != true"
      :class="{
        border: hideTitle != true,
      }"
      class="border border-medium-tint rounded bg-surface p-4"
    >
      <QuillEditor
        :toolbar="[]"
        :content="quillContent"
        :read-only="true"
        :enable="false"
      />
    </div>
    <BaseTextEditor
      v-if="isArticleMigrated"
      :model-value="article.content"
      :read-only="true"
      class="border border-medium-tint rounded bg-surface p-4"
    />
  </div>
</template>

<style>
#article-viewer .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: unset;
}

#article-viewer .ql-toolbar {
  display: none;
}

#article-viewer .ql-container.ql-snow {
  border: unset;
}

#article-viewer .ql-container p {
  @apply text-body-1;
}
</style>
